.container {
    margin: auto;
    width: 50%;
    outline-style: solid;
    outline-color: lightgray;
    padding: 1em 2em;
    height: 80%;
    margin-bottom: 5em;
    line-height: 1.5rem;
}
.blurb {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 80%;
    }
}  
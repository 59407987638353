
.fullbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 0;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
}

.menuitem {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'VT323', monospace;
    padding-bottom: 0.2em;
    position: relative;
}

.menuitem:hover {
    color: lightgray;
}

.square {
    width: 8px;
    height: 8px;
    background: white;
    margin: 0 0.5em;
  }
    

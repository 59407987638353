.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 1.5em 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.welcome{
    color: white;
    font-size: 36px;
    font-family: 'Major Mono Display', monospace;
    margin: 0;
    text-align: center;
}
.caption{
    color: white;
    font-size: 20px;
    font-family: 'Major Mono Display', monospace;
    text-align: center;
}

.timer {
    color: white;
    font-size: 24px;
    font-family: 'Major Mono Display', monospace;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    outline-color: white;
    outline-style: double;
    outline-color: lightgray;
    padding: 0.5em;
}

.pic{
    margin:0;
}
.bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    position: 'absolute';
    bottom: '0';
}

.menuitem {
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    font-family: 'VT323', monospace;
    padding-bottom: 0.2em;
    position: relative;
}

.menuitem:hover {
    color: lightgray;
}

.square {
    width: 8px;
    height: 8px;
    background: white;
    margin: 0 0.5em;
  }
    

.title {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: left;
    font-weight: bolder;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.title span {
    font-weight: 400;
    font-size: 18px;
}

.killBlurb {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    text-align: left;
}

.killBlurb img {
    margin-right: 12px;
    height: 10px;
}

.feedContainer{
    margin: auto;
    width: 50vw;
    outline-style: solid;
    outline-color: lightgray;
    padding: 1em;
    height: 29.5vh;
    line-height: 1.5rem;
    overflow: scroll;
}

.feedContainer::-webkit-scrollbar{
    display: none;
}

@media only screen and (max-width: 600px) {
    .feedContainer {
        width: 80%;
    }
}

.container {
    color: white;
    margin: auto;
    width: 60%;
    outline-style: solid;
    outline-color: lightgray;
    padding: 1em 2em;
    height: 80%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin-bottom: 5em;
    line-height: 1.5rem;
}

.heading {
    font-size: 20px;
    font-weight: bolder;

}
.bigheading {
    font-size: 3em;
    font-weight: bolder;
    text-align: center;
}

.sub {
    font-size: 25px;
    text-align: center;
    font-weight: bolder;
    font-style: italic;
}

.question {
    font-size: 1.25em;
}

.answer {
    font-style: italic;
}


@media only screen and (max-width: 600px) {
    .container {
        width: 80%;
    }
}  